import "./../App.css";

function LogoTop() {

    return (
        <div className="text-center mb-4 dashboard-font-design">
            <h1 className="display-4">OptiMate</h1>
            <p className="lead">simply VIEWtiful</p>
        </div>
    )
}

export default LogoTop;